.reviewSlider {
	background-color: #e1e2e1;
	padding: 170px 0;
	.sliderCard {
		max-width: 100%;
		min-height: 450px;
		display: flex !important;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.cardContainer {
			background: #fff;
			width: 90%;
			max-width: 1200px;
			min-height: 475px;
			position: relative;
			display: flex;
			justify-content: center;
			.cardQuotes {
				width: 170px;
				height: 170px;
				position: absolute;
				top: 60px;
				left: -40px;
				background: #f5a931;
				display: flex;
				justify-content: center;
				align-items: center;
				@media screen and (max-width: 992px) {
					display: none;
				}
			}
			.textHolder {
				position: relative;
				padding: 20px 40px;
				margin: 30px 60px 30px 150px;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				@media screen and (max-width: 992px) {
					margin: 30px 60px;
				}

				@media screen and (max-width: 600px) {
					padding: 0;
				}

				.reviewText {
					font-size: 25px;
					color: #1c1c1c;
					margin-bottom: 20px;
					font-weight: 400;
					position: relative;
					//text-align: justify;
				}

				.clientInfo {
					.clientRating {
						display: flex;
						align-items: center;
						padding: 20px 0;
						.ratingNumber {
							font-size: 25px;
							margin-right: 15px;
						}
						.ratingStars {
							height: 24px;
						}
					}
					.clientName {
						color: #faa819;
						font-size: 26px;
						font-weight: 500;
						margin-bottom: 10px;
					}

					.clientWebsite {
						font-size: 26px;
						color: #1c1c1c;
						font-weight: 400;
					}
				}
			}
		}
	}
}
